<template>
    <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
        <!---弹性布局flex布局开始-->
        <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartreportlist_flex_0">
            <a-form-model ref="formRef1687851831414" class="benben-flex-form heartreportlist_fd0_0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                <a-row class="benben-grid width_fullscreen heartreportlist_fd0_0_c0" layout="4:4:4:4:4:4" :gutter="[8,8]">

                    <a-col :span="4">
                        <a-form-model-item need="false" :rules="[]" :colon="true" label="账号">
                            <a-input class="heartreportlist_fd0_0_c0_c0_c0" :disabled="false" :max-length="10" placeholder="请输入" :allow-clear="true" size="default">
                            </a-input>
                        </a-form-model-item>

                    </a-col>



                    <a-col :span="4">
                        <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报账号">
                            <a-input class="heartreportlist_fd0_0_c0_c1_c0" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                            </a-input>
                        </a-form-model-item>

                    </a-col>



                    <a-col :span="4">
                        <a-form-model-item need="false" :rules="[]" :colon="true" label="处理状态">
                            <simple-select :disabled="false" placeholder="请选择" mode="default" :options="fieldName_9859" :field-names='{"label":"name","value":"aid","children":"child"}'>

                            </simple-select>
                        </a-form-model-item>

                    </a-col>



                    <a-col :span="4">
                        <a-form-model-item need="false" :rules="[]" :colon="true" label="举报时间">
                            <a-config-provider :locale="locale">
                                <a-date-picker class="heartreportlist_fd0_0_c0_c3_c0" :disabled="false" :allow-clear="true" size="default" placeholder="请选择" value-format="YYYY-MM-DD" format="YYYY-MM-DD">
                                </a-date-picker>
                            </a-config-provider>
                        </a-form-model-item>

                    </a-col>



                    <a-col :span="4">
                        <a-button class="heartreportlist_fd0_0_c0_c4_c0" :disabled="false" type="primary">搜索</a-button>

                        <a-button :disabled="false">重置</a-button>


                    </a-col>



                    <a-col :span="4">

                    </a-col>

                </a-row>
            </a-form-model>
        </div>
        <!---弹性布局flex布局结束-->

        <!---弹性布局flex布局开始-->
        <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartreportlist_flex_1">
            <a-button class="heartreportlist_fd1_0" :disabled="false" type="primary">导出</a-button>

        </div>
        <!---弹性布局flex布局结束-->

        <a-drawer id="antdVue" class="  heartreportlist_modal_2" :centered="false" :closable="true" :mask="true" :mask-closable="true" placement="right" title="举报详情" width="50%" height="50%" :z-index="1000">
            <a-form-model ref="formRef1687854976851" class="benben-flex-form heartreportlist_modal2_0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人账号">
                    <a-input class="heartreportlist_modal2_0_c0" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c1" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人账号">
                    <a-input class="heartreportlist_modal2_0_c2" :disabled="false" :max-length="-1" placeholder="被举报人账号" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c3" :disabled="false" :max-length="-1" placeholder="被举报人姓名" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报原因">
                    <a-textarea class="heartreportlist_modal2_0_c4" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报描述">
                    <a-textarea class="heartreportlist_modal2_0_c5" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报图片">
                    <upload-image class="position-relative heartreportlist_modal2_0_c6" :disabled="false" :show-file-list="false" :multiple="false" :is-cropp="true" :is-image="true" :limit="1" list-type="picture-card" accept=".png,.jpg" action="#" :height="106">
                    </upload-image>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报视频">
                    <upload-media class="position-relative heartreportlist_modal2_0_c7" :disabled="false" :show-file-list="false" :multiple="false" :is-image="false" :limit="1" list-type="picture-card" accept="video/*" action="#" :height="106" media-type="video">
                    </upload-media>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报时间">
                    <a-input class="heartreportlist_modal2_0_c8" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="状态">
                    <a-input class="heartreportlist_modal2_0_c9" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="操作">
                    <a-radio-group :disabled="false" tagType="dot" button-style="solid">
                        <a-radio value="1">
                            选项1
                        </a-radio>
                        <a-radio value="2">
                            选项2
                        </a-radio>
                        <a-radio value="">
                            配置原因33
                        </a-radio>
                        <a-radio value="">
                            配置原因44444
                        </a-radio>
                        <a-radio value="">
                            配置原因555555
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="false" label=" ">
                    <a-textarea class="heartreportlist_modal2_0_c11" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
            </a-form-model>
            <a-form-model ref="formRef1687854976851" class="benben-flex-form heartreportlist_modal2_0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人账号">
                    <a-input class="heartreportlist_modal2_0_c0" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c1" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人账号">
                    <a-input class="heartreportlist_modal2_0_c2" :disabled="false" :max-length="-1" placeholder="被举报人账号" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c3" :disabled="false" :max-length="-1" placeholder="被举报人姓名" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报原因">
                    <a-textarea class="heartreportlist_modal2_0_c4" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报描述">
                    <a-textarea class="heartreportlist_modal2_0_c5" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报图片">
                    <upload-image class="position-relative heartreportlist_modal2_0_c6" :disabled="false" :show-file-list="false" :multiple="false" :is-cropp="true" :is-image="true" :limit="1" list-type="picture-card" accept=".png,.jpg" action="#" :height="106">
                    </upload-image>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报视频">
                    <upload-media class="position-relative heartreportlist_modal2_0_c7" :disabled="false" :show-file-list="false" :multiple="false" :is-image="false" :limit="1" list-type="picture-card" accept="video/*" action="#" :height="106" media-type="video">
                    </upload-media>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报时间">
                    <a-input class="heartreportlist_modal2_0_c8" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="状态">
                    <a-input class="heartreportlist_modal2_0_c9" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="操作">
                    <a-radio-group :disabled="false" tagType="dot" button-style="solid">
                        <a-radio value="1">
                            选项1
                        </a-radio>
                        <a-radio value="2">
                            选项2
                        </a-radio>
                        <a-radio value="">
                            配置原因33
                        </a-radio>
                        <a-radio value="">
                            配置原因44444
                        </a-radio>
                        <a-radio value="">
                            配置原因555555
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="false" label=" ">
                    <a-textarea class="heartreportlist_modal2_0_c11" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
            </a-form-model>
            <div class="flex flex-wrap align-center justify-end flex-wrap align-center heartreportlist_modal2_1" isAlone="false">
                <a-button class="heartreportlist_modal2_1_c0" :disabled="false">取消</a-button>

                <a-button class="heartreportlist_modal2_1_c1" :disabled="false" type="primary">确定</a-button>


            </div>

            <a-form-model ref="formRef1687854976851" class="benben-flex-form heartreportlist_modal2_0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人账号">
                    <a-input class="heartreportlist_modal2_0_c0" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c1" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人账号">
                    <a-input class="heartreportlist_modal2_0_c2" :disabled="false" :max-length="-1" placeholder="被举报人账号" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="被举报人姓名">
                    <a-input class="heartreportlist_modal2_0_c3" :disabled="false" :max-length="-1" placeholder="被举报人姓名" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报原因">
                    <a-textarea class="heartreportlist_modal2_0_c4" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报描述">
                    <a-textarea class="heartreportlist_modal2_0_c5" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报图片">
                    <upload-image class="position-relative heartreportlist_modal2_0_c6" :disabled="false" :show-file-list="false" :multiple="false" :is-cropp="true" :is-image="true" :limit="1" list-type="picture-card" accept=".png,.jpg" action="#" :height="106">
                    </upload-image>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报视频">
                    <upload-media class="position-relative heartreportlist_modal2_0_c7" :disabled="false" :show-file-list="false" :multiple="false" :is-image="false" :limit="1" list-type="picture-card" accept="video/*" action="#" :height="106" media-type="video">
                    </upload-media>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="举报时间">
                    <a-input class="heartreportlist_modal2_0_c8" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="状态">
                    <a-input class="heartreportlist_modal2_0_c9" :disabled="false" :max-length="-1" placeholder="请输入" :allow-clear="true" size="default">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="true" label="操作">
                    <a-radio-group :disabled="false" tagType="dot" button-style="solid">
                        <a-radio value="1">
                            选项1
                        </a-radio>
                        <a-radio value="2">
                            选项2
                        </a-radio>
                        <a-radio value="">
                            配置原因33
                        </a-radio>
                        <a-radio value="">
                            配置原因44444
                        </a-radio>
                        <a-radio value="">
                            配置原因555555
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item need="false" :rules="[]" :colon="false" label=" ">
                    <a-textarea class="heartreportlist_modal2_0_c11" :disabled="false" placeholder="请输入" :max-length="240" :auto-size="true">
                    </a-textarea>
                </a-form-model-item>
            </a-form-model>
            <div class="flex flex-wrap align-center justify-end flex-wrap align-center heartreportlist_modal2_1" isAlone="false">
                <a-button class="heartreportlist_modal2_1_c0" :disabled="false">取消</a-button>

                <a-button class="heartreportlist_modal2_1_c1" :disabled="false" type="primary">确定</a-button>


            </div>

            <a-button :disabled="false">按钮</a-button>

        </a-drawer>

        <!---弹性布局flex布局开始-->
        <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartreportlist_flex_3">
            <div class="flex  flex-wrap align-center heartreportlist_fd3_0" isAlone="false" >
                <a-table class="heartreportlist_otherTablefd3_0_c0" tableLayout="fixed" :bordered="true" :scroll="{y:500}" size="small" :pagination="false" :data-source="data" :columns="columns_4200" :row-key="record=>record.aid">
                    <template slot="operation" slot-scope="text,record,index"> <a href="javascript:;" @click="()=>{}" style="color:#e6a23c;">查看详情</a>
                    </template>

                </a-table>


            </div>

        </div>
        <!---弹性布局flex布局结束-->



    </div>
</template>

<script>
    import {
        receive,
        benbenSelectDiy,
        benbenFlexNumberBox,
        scrollList,
        treeSelect,
        simpleSelect,
        multipleSelect,
        cascaderForm,
        checkboxForm,
        switchForm,
        messageReply,
        rangePicker,
        handSignature,
        mixinAddTableItem,
        editorForm,
        qiunDataCharts,
        copyText,
        mixinTableItemChange,
        in_array,
        themeColorInit,
        getUrlKey,
        clearDataPass,
        isEmpty
    } from '@/assets/diypaaspc/diy-pass-pc.js'
    import {
        provinceForm
    } from '@/assets/diypaaspc/province_area.js'
    import request from '@/utils/request'
    export default {
        name: "goods",
        components: {
            qiunDataCharts,
            benbenSelectDiy,
            //            productSelect, 
            benbenFlexNumberBox,
            scrollList,
            treeSelect,
            simpleSelect,
            messageReply,
            rangePicker,
            multipleSelect,
            provinceForm,
            editorForm,
            cascaderForm,
            switchForm,
            checkboxForm,
            //            uploadImage,
            //            uploadFile,
            //            uploadMedia,
            //            uploadSingleFile,
            handSignature
        },
        data() {
            data: [],
            receive(this);
            const themeColorConfig = {
                "presetApi": {
                    "getClassifyOne": "",
                    "getClassifyTow": "",
                    "getClassifyShop": ""
                },
                "themeType": 0,
                "themeConfig": [{
                    "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                    "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                    "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
                }]
            };
            return {
                themeColorConfig,
                "columns_4200": [{
                    "show": false,
                    "title": "举报人账号",
                    "dataIndex": "name",
                    "key": "name",
                    "fixed": false,
                    "align": "left",
                    "ellipsis": true,
                    "type": "text",
                    "scopedSlots": {
                        "customRender": "name"
                    }
                }, {
                    "show": false,
                    "title": "举报人姓名",
                    "dataIndex": "age",
                    "key": "1687852706357",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "被举报人账号",
                    "dataIndex": "age",
                    "key": "1687852767489",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "被举报人姓名",
                    "dataIndex": "age",
                    "key": "1687852584097",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "举报人原因",
                    "dataIndex": "age",
                    "key": "1687853104534",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "举报描述",
                    "dataIndex": "age",
                    "key": "1687852673213",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "处理状态",
                    "dataIndex": "age",
                    "key": "1687852478801",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "举报时间",
                    "dataIndex": "age",
                    "key": "1687853051413",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": false,
                    "title": "处理人",
                    "dataIndex": "age",
                    "key": "age",
                    "sorter": false,
                    "type": "text",
                    "fixed": false,
                    "align": "left",
                    "scopedSlots": {
                        "customRender": "age"
                    }
                }, {
                    "show": true,
                    "title": "操作",
                    "dataIndex": "operation",
                    "key": "1687852955393",
                    "fixed": false,
                    "align": "left",
                    "ellipsis": true,
                    "type": "operation",
                    "scopedSlots": {
                        "customRender": "operation"
                    }
                }],
                "fieldName_9859": [{
                    "aid": "1",
                    "name": "选项1"
                }, {
                    "aid": "2",
                    "name": "选项2"
                }]
            };

        },
        computed: {

        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {

        },
        watch: {},
        methods: {
            copyText,
            mixinTableItemChange,
            in_array,
            themeColorInit,
            isEmpty,

        }
    }
</script>

<style lang="less" scoped>
    @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

    .antdVue {
        width: 100%;
        background: #F8F8F8;
        background-size: 100% auto !important;
    }

    #antdVue .heartreportlist_flex_0 {
        background: #fff;
        width: 100%;
        min-height: 100px;
    }

    #antdVue .heartreportlist_fd0_0_c0_c4_c0 {
        background: var(--benbenbgColor2);
        background-size: 100% auto !important;
        margin: 0px 10px 0px 20px;
    }

    #antdVue .heartreportlist_fd0_0_c0_c3_c0 {
        width: 100%;
    }

    #antdVue .heartreportlist_fd0_0_c0_c1_c0 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_fd0_0_c0_c0_c0 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_fd0_0_c0 {
        background: #fff;
        width: 100%;
        padding: 20px;
    }

    #antdVue .heartreportlist_fd0_0 {
        background: #fff;
        width: 100%;
        min-height: 100px;
    }

    #antdVue .heartreportlist_flex_1 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartreportlist_fd1_0 {
        background: var(--benbenbgColor2);
        background-size: 100% auto !important;
        margin: 0px 0px 0px 20px;
    }

    #antdVue .heartreportlist_modal2_1_c1 {
        background: var(--benbenbgColor2);
        background-size: 100% auto !important;
    }

    #antdVue .heartreportlist_modal2_1_c0 {
        margin: 0px 10px 0px 0px;
    }

    #antdVue .heartreportlist_modal2_1 {
        border-top: 1px solid #eee;
        background: var(--benbenbgColor1);
        width: 100%;
        margin: 20px 0px 50px 0px;
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-size: 100% auto !important;
        padding: 20px 20px 30px 20px;
    }

    #antdVue .heartreportlist_modal2_0_c11 {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        min-height: 100px;
        margin: 0px 0px 100px 0px;
    }

    #antdVue .heartreportlist_modal2_0_c9 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0_c8 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0_c7 {
        width: 100%;
    }

    #antdVue .heartreportlist_modal2_0_c6 {
        width: 100%;
    }

    #antdVue .heartreportlist_modal2_0_c5 {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        min-height: 100px;
    }

    #antdVue .heartreportlist_modal2_0_c4 {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        min-height: 100px;
    }

    #antdVue .heartreportlist_modal2_0_c3 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0_c2 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0_c1 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0_c0 {
        font-size: 14px;
    }

    #antdVue .heartreportlist_modal2_0 {
        background: #fff;
        width: 100%;
        min-height: 100px;
    }

    #antdVue .heartreportlist_modal_2 {
        width: 100%;
    }

    #antdVue .heartreportlist_flex_3 {
        background: #fff;
        width: 100%;
        min-height: 100px;
    }

    #antdVue .heartreportlist_otherTablefd3_0_c0 {
        margin: 20px 0px 0px 0px;
    }

    #antdVue .heartreportlist_fd3_0 {
        width: 100%;
    }
</style>